import React from 'react';
import { BookmarkSquareIcon, BookOpenIcon, ChevronRightIcon, QueueListIcon, RssIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router";

const NotFoundPage = () => {
    const navigator = useNavigate();

    const links = [
        {
            name: 'New request',
            href: '/request',
            description: 'Create a new shipping request in minutes.',
            icon: BookOpenIcon,
        },
        {
            name: 'Track your order',
            href: '/tracking',
            description: 'See what is what is happening with your order.',
            icon: QueueListIcon
        },
        {
            name: 'Frequently asked questions',
            href: '/faq',
            description: 'We have an active community that can help you.',
            icon: BookmarkSquareIcon,
        },
        {
            name: 'About Us',
            href: '/about',
            description: 'Leave a note for us, and we will get back to you',
            icon: RssIcon
        },
    ]

    return (<div className="bg-white">
        <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">

            <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
                <p className="text-base font-semibold leading-8 text-sky-600">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">This page does not
                    exist</h1>
                <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
                    Sorry, we couldn’t find the page you’re looking for.
                </p>
            </div>
            <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
                <h2 className="sr-only">Popular pages</h2>
                <ul className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
                    {links.map((link, linkIdx) => (
                        <li key={linkIdx} onClick={() => navigator(link.href)} className="relative flex gap-x-6 py-6 hover:bg-gray-200 px-4 hover:cursor-pointer">
                            <div
                                className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                                <link.icon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                            </div>
                            <div className="flex-auto">
                                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                    <div>
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        {link.name}
                                    </div>
                                </h3>
                                <p className="mt-2 text-sm leading-6 text-gray-600">{link.description}</p>
                            </div>
                            <div className="flex-none self-center">
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="mt-10 flex justify-center">
                    <div onClick={() => navigator('/')} className="text-sm font-semibold leading-6 text-sky-600 hover:cursor-pointer hover:text-sky-400">
                        <span aria-hidden="true">&larr;</span>
                        Back to home
                    </div>
                </div>
            </div>
        </main>
    </div>)
};

export default NotFoundPage;