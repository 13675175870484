
const ConfirmationDestination = ({ formValues }) => {

	return (
		<>
			<div className="px-4 pt-10 pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
				<dd className="mt-1 text-center text-4xl font-semibold leading-7 text-gray-700 sm:col-span-3 sm:mt-0">
					{formValues.title}
				</dd>
			</div>

			<div className="px-4 pt-5 pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
				<dt className="text-xl font-medium leading-6 text-gray-600">Date</dt>
				<dd className="mt-1 text-3xl font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{formValues.date}
				</dd>
			</div>

			<div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4">
				<dt className="text-xl font-medium leading-6 text-gray-600">Time</dt>
				<dd className="mt-1 text-3xl font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{formValues.time}
				</dd>
			</div>

			<div className="px-4 pb-16 sm:grid sm:grid-cols-3 sm:gap-4">
				<dt className="text-xl font-medium leading-6 text-gray-600">Address</dt>
				<dd className="mt-1 text-3xl font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
					{formValues.address}
				</dd>
			</div>
		</>
	);
};

export default ConfirmationDestination;
