import { Listbox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const ItemsFormStageV3 = ({ setFormValues, goNext, formValues, goBack, itemKey }) => {
    const [selected, setSelected] = useState([]);
    const items = useSelector(state => state.cabinet.utils.profile.items);

    const handleFormValues = () => {
        setFormValues({ ...formValues, items: selected });
    };

    const handleGoNext = () => {
        handleFormValues();
        goNext();
    };

    return (
        <div className="relative">
            <div className="relative">
                <Listbox value={selected} onChange={setSelected} multiple>
                    {({ open }) => (
                        <>
                            {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                                Select Item
                            </Listbox.Label> */}
                            <div className="relative mt-2">
                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6">
                                    <span className="block truncate">
                                        {selected.length === 0
                                            ? 'Select items'
                                            : `${selected.length} item${selected.length === 1 ? '' : 's'} selected`}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Listbox.Button>
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {items.map((item) => (
                                        <Listbox.Option
                                            key={item.id}
                                            value={item}
                                            className={({ active, selected }) =>
                                                `relative cursor-pointer select-none py-2 pl-3 pr-9 
                                                ${active ? 'bg-sky-600 text-white' : 'text-gray-900'}
                                                ${selected ? 'font-semibold' : 'font-normal'}`
                                            }
                                        >
                                            {({ active, selected }) => (
                                                <>
                                                    <span className="block truncate">
                                                        {item.item.title}
                                                    </span>
                                                    {selected && (
                                                        <span className={`absolute inset-y-0 right-0 flex items-center pr-3 
                                                            ${active ? 'text-white' : 'text-sky-600'}`}>
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>

                                {/* Add selected items tags */}
                                {selected.length > 0 && (
                                    <div className="flex flex-wrap gap-2 mt-2">
                                        {selected.map((item) => (
                                            <span
                                                key={item.id}
                                                className="inline-flex items-center gap-1 px-2 py-1 rounded-md bg-sky-100 text-sky-700 text-sm"
                                            >
                                                {item.item.title}
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelected(selected.filter((i) => i.id !== item.id));
                                                    }}
                                                    className="hover:text-sky-900"
                                                >
                                                    ×
                                                </button>
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </Listbox>
            </div>

            <div className="flex flex-col sm:flex-row justify-center space-y-4 my-4 sm:space-y-0 sm:space-x-4">
                {itemKey !== 0 && (
                    <button
                        onClick={goBack}
                        type="button"
                        className="w-full sm:w-auto px-6 py-3 bg-gray-200 text-gray-700 text-sm font-semibold rounded-md shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                    >
                        Back
                    </button>
                )}
                <button
                    onClick={handleGoNext}
                    type="button"
                    className="w-full sm:w-auto px-6 py-3 bg-sky-600 text-white text-sm font-semibold rounded-md shadow-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ItemsFormStageV3;
