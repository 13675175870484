export const SET_LOADING_CABINET = "SET_LOADING_CABINET";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_RESPONSE = "SET_AUTH_RESPONSE";
export const SET_LOADING_AUTH = "SET_LOADING_AUTH";
export const SET_LOADING_PROFILE = "SET_LOADING_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_REQUEST_FORM = "SET_REQUEST_FORM";

export const loadingCabinet = (payload) => ({
	type: SET_LOADING_CABINET,
	payload,
});

export const loadingProfile = (payload) => ({
	type: SET_LOADING_PROFILE,
	payload,
});

export const setUserProfile = (payload) => ({
	type: SET_USER_PROFILE,
	payload,
});

export const loadingAuth = (payload) => ({
	type: SET_LOADING_AUTH,
	payload,
});

export const setAuthUser = (payload) => ({ type: SET_AUTH_USER, payload });

export const setAuthResponse = (payload) => ({
	type: SET_AUTH_RESPONSE,
	payload,
});

const initState = {
	loading: false,
	loadingAuth: false,
	loadingProfile: false,
	user: null,
	response: null,
	requestForm: null,
	profile: null,
};

export const utilReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_LOADING_CABINET:
			return { ...state, loading: action.payload };
		case SET_LOADING_AUTH:
			return { ...state, loadingAuth: action.payload };
		case SET_AUTH_USER:
			return { ...state, user: action.payload };
		case SET_AUTH_RESPONSE:
			return { ...state, response: action.payload };
		case SET_USER_PROFILE:
			return { ...state, profile: action.payload };
		case SET_REQUEST_FORM:
			return { ...state, requestForm: action.payload };
		case SET_LOADING_PROFILE:
			return { ...state, loadingProfile: action.payload };
		default:
			return state;
	}
};
