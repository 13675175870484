import StatusBar from "../tracking/StatusBar";
import {useSelector} from "react-redux";

export function ConfirmationTimeline() {
    const tracking = useSelector((state) => state.confirmation?.data?.tracking);
    if(!tracking) return null;
    return (
        <div>
            <StatusBar {...{status: tracking.status}} />
        </div>
    )
}