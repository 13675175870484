import { loadingCabinet } from "../../util.reducer";
import { core } from "../../../index";
import { setPastListing } from "../reducer/action";

export const getPast = (page) => (dispatch) => {
	dispatch(loadingCabinet(true));

	core
		.get(`api/cabinet/past?page=${page}`)
		.then(({ data }) => {
			dispatch(setPastListing(data));
			dispatch(loadingCabinet(false));
		})
		.catch((e) => {
			dispatch(loadingCabinet(false));
		});
};
