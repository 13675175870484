import {BookOpenIcon, CheckIcon, ChevronRightIcon, QueueListIcon,} from "@heroicons/react/20/solid";
import React from "react";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";

const links = [
	{
		name: "New request",
		href: "/request",
		description: "Create a new shipping request in minutes.",
		icon: BookOpenIcon,
	},
	{
		name: "Track your order",
		href: "/tracking",
		description: "See what is what is happening with your order.",
		icon: QueueListIcon,
	},
	// {
	//     name: 'Frequently asked questions',
	//     href: '#',
	//     description: 'We have an active community that can help you.',
	//     icon: BookmarkSquareIcon,
	// },
	// {
	//     name: 'Contact Us',
	//     href: '#',
	//     description: 'Leave a note for us, and we will get back to you',
	//     icon: RssIcon
	// },
];

const ConfirmationSuccess = ({children, formValues}) => {
	const navigate = useNavigate();
	const success = useSelector((state) => state.confirmation.success);
	const data = useSelector((state) => state.confirmation.data);

	if (!success) return children;

	console.log({
		formValues
	})

	return (
		<main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
			<div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
				<div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-green-100 mt-5">
					<CheckIcon
						className="h-12 w-12 font-medium text-green-600"
						aria-hidden="true"
					/>
				</div>

				<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
					Thank you for confirming
				</h1>

				<div className="mt-4 text-base leading-7 text-gray-800 sm:mt-6 sm:text-lg sm:leading-8">
					{
						<div>
							<div className="px-4 sm:px-0">
								<h3 className="text-base/7 font-semibold text-gray-900">
									Request Information
								</h3>
							</div>
							<div className="mt-6 border-t border-gray-100">
								<dl className="divide-y divide-gray-100">
									<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-lg/6 font-medium text-gray-900">
											Date
										</dt>
										<dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
											{formValues.date}
										</dd>
									</div>
									<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-lg/6 font-medium text-gray-900">
											Time
										</dt>
										<dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
											{formValues.time}
										</dd>
									</div>
									<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
										<dt className="text-lg/6 font-medium text-gray-900">
											Address
										</dt>
										<dd className="mt-1 text-lg/6 text-gray-700 sm:col-span-2 sm:mt-0">
											{formValues.address}
										</dd>
									</div>
								</dl>
							</div>
							{(data.show_one || data.show_ground) && (
								<section aria-labelledby="policies-heading" className={"pb-4"}>

									<dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
										{data.show_one && (<div
											className="rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
											<dt className="text-center">
											<span className="mt-4 text-2xl font-medium text-gray-700">
												Please note, this job is booked for 1 driver
											</span>
											</dt>
											<dd className="mt-1 text-sm text-gray-500"></dd>
										</div>)}

										{data.show_ground && (<div
											className="rounded-lg border border-gray-200 bg-gray-50 p-6 text-center">
											<dt className="text-center">
											<span className="mt-4 text-2xl font-medium text-gray-700">
												Please note, this is a ground floor job only
											</span>
											</dt>
											<dd className="mt-1 text-sm text-gray-500"></dd>
										</div>)}
									</dl>
								</section>)}
						</div>
					}
				</div>
			</div>
			<div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
				<ul className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
					{links.map((link, linkIdx) => (
						<li
							key={linkIdx}
							onClick={() => navigate(link.href)}
							className="relative flex gap-x-6 py-6 cursor-pointer hover:bg-gray-200 transition-colors duration-200"
						>
							<div
								className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
								<link.icon
									className="h-6 w-6 text-sky-600"
									aria-hidden="true"
								/>
							</div>
							<div className="flex-auto">
								<h3 className="text-sm font-semibold leading-6 text-gray-900">
									<div>
										<span className="absolute inset-0" aria-hidden="true"/>
										{link.name}
									</div>
								</h3>
								<p className="mt-2 text-sm leading-6 text-gray-600">
									{link.description}
								</p>
							</div>
							<div className="flex-none self-center">
								<ChevronRightIcon
									className="h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</div>
						</li>
					))}
				</ul>
				<div className="mt-10 flex justify-center">
					<div
						className="text-sm font-semibold leading-6 text-sky-600 cursor-pointer hover:border-b-1 border-sky-300 transition-colors duration-200"
						onClick={() => navigate("/")}
					>
						Back to main page
					</div>
				</div>
			</div>
		</main>
	);
};

export default ConfirmationSuccess;
