import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addressSearch } from "../../request/services/requestService";
import { setRequestFormSearchAddress } from "../../request/reducer/action";

const AnimatedAutoCompleteProxy = ({
    label,
    onChange,
    name,
    value,
    onBlur,
}) => {
    const [inputValue, setInputValue] = useState(value);
    const [filteredData, setFilteredData] = useState([]);
    const [showList, setShowList] = useState(false);
    const inputRef = useRef("");
    const dispatch = useDispatch();

    const items = useSelector(state => state.request.formSearchAddress)

    const handleOnChange = (e) => {
        setInputValue(e.target.value);
        onChange(e);
        // dispatch()
        dispatch(addressSearch(e.target.value));
    };

    const handleOnClick = (item) => {
        setInputValue(item);
        onChange({ target: { value: item } });
        dispatch(setRequestFormSearchAddress([]));
        setFilteredData([]);
        setShowList(false);
    };

    useEffect(() => {
        const processed = items.filter((item) =>
            item.toLowerCase().includes(inputValue.toLowerCase())
        ).filter(item => item !== inputValue);

        setFilteredData(processed);
        setShowList(processed.length > 0);

        return () => {
            setFilteredData([]);
            setShowList(false);
        };
    }, [items, inputValue]);
    useEffect(() => {
        setRequestFormSearchAddress([]);
    }, []);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    // useEffect(() => {
    //     //  (filteredData.count === 1 && filteredData[0] === inputValue))
    //     // setInputValue(value);
    // }, [items]);

    return (
        <div className="relative">
            <div className="relative">
                <input
                    type="text"
                    ref={inputRef}
                    id={name}
                    name={name}
                    onChange={handleOnChange}
                    onBlur={onBlur}
                    value={inputValue || ""}
                    className={`
						rounded-md peer w-full h-10 border-b-2 
						border-gray-300 focus:outline-none has-value focus:border-sky-500
					`}
                />
                <label
                    htmlFor={name}
                    className={`
						absolute bg-white px-1 left-4 transition-all duration-100 ease-in-out 
						text-gray-500 ${!!inputValue ? " -top-2.5 text-xs " : " top-2 "
                        }peer-placeholder-shown:text-gray-600 peer-placeholder-shown:text-xs
						peer-placeholder-shown:top-2 peer-placeholder-shown:left-0 peer-focus:-top-2.5
						peer-focus:text-sky-500 peer-focus:text-xs peer-focus:left-4`}
                >
                    {label}
                </label>
            </div>
            {showList && (
                <ul className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto transition duration-300 ease-in-out">
                    {filteredData.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => handleOnClick(item)}
                            className="p-2 hover:bg-sky-500 hover:text-white cursor-pointer"
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};


export default AnimatedAutoCompleteProxy;



