import { useEffect } from "react";
import { getUserProfile } from "../profile/profileServices";
import { useDispatch } from "react-redux";
import RequestFormV2 from "../../request/page/RequestFormV2";
import LoadingView from "../../request/page/LoadingView";
import ErrorView from "../../request/page/ErrorView";
import SuccessView from "../../request/page/SuccessView";

const CabinetRequestForm = () => {
	document.title = "Request a quote | Delivery Network";
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUserProfile());
	}, [dispatch]);

	return (
		<div>
			<div className={"pt-10"}>
				<LoadingView>
					<ErrorView>
						<SuccessView>
							<RequestFormV2 />
						</SuccessView>
					</ErrorView>
				</LoadingView>
			</div>
		</div>
	);
};

export default CabinetRequestForm;
