import { core } from "../..";
import { requestFormLoading, requestFormShowError, requestFormShowSuccess } from "../../request/reducer/action";
import {
	loadingCabinet,
	loadingProfile,
	setUserProfile,
} from "../util.reducer";

export const getUserProfile = () => (dispatch) => {
	dispatch(loadingCabinet(true));
	dispatch(loadingProfile(true));
	dispatch(requestFormLoading(true));

	core
		.get("api/cabinet/user-profile")
		.then((r) => {
			dispatch(setUserProfile(r.data.profile));
			// dispatch(setRequestData(r.data));
		})
		.catch(() => { })
		.finally(() => {
			dispatch(loadingCabinet(false));
			dispatch(requestFormLoading(false));
			dispatch(loadingProfile(false));
		});
};

export const updateUserProfile = (values) => (dispatch) => {
	core
		.put("api/cabinet/user-profile/" + values.id, values)
		.then((r) => {
			dispatch(setUserProfile(r.data.profile));
		})
		.catch(() => { })
		.finally(() => dispatch(loadingCabinet(false)));
};

export const submitCabinetRequest = (values) => (dispatch) => {
	dispatch(requestFormLoading(true));

	core
		.post(`api/cabinet/request`, values, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		})
		.then(() => dispatch(requestFormShowSuccess(true)))
		.catch(() => {
			dispatch(requestFormShowError(true));
			dispatch(requestFormShowSuccess(false));
		})
		.finally(() => dispatch(requestFormLoading(false)));
};


