import {
	BookOpenIcon,
	ChevronRightIcon,
	QueueListIcon,
	XMarkIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const links = [
	{
		name: "New request",
		href: "/request",
		description: "Create a new shipping request in minutes.",
		icon: BookOpenIcon,
	},
	{
		name: "Track your order",
		href: "/tracking",
		description: "See what is what is happening with your order.",
		icon: QueueListIcon,
	},
	// {
	//     name: 'Frequently asked questions',
	//     href: '#',
	//     description: 'We have an active community that can help you.',
	//     icon: BookmarkSquareIcon,
	// },
	// {
	//     name: 'Contact Us',
	//     href: '#',
	//     description: 'Leave a note for us, and we will get back to you',
	//     icon: RssIcon
	// },
];

const ConfirmationError = ({ children }) => {
	const navigate = useNavigate();
	const error = useSelector((state) => state.confirmation.error);

	if (!error) return children;

	return (
		<>
			<div className="mx-auto max-w-2xl text-center">
				<div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full bg-red-100 mt-5">
					<XMarkIcon
						className="h-12 w-12 font-medium text-red-600"
						aria-hidden="true"
					/>
				</div>

				<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
					We're sorry! An unexpected error occurred on our side, and we were unable to confirm the details.
				</h1>

				<p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
					Please reach your transport provider and confirm the details with them directly
				</p>
			</div>
			<div className="mx-auto mt-8 flow-root max-w-lg">
				<ul className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
					{links.map((link, linkIdx) => (
						<li
							key={linkIdx}
							onClick={() => navigate(link.href)}
							className="relative flex gap-x-6 py-6 cursor-pointer hover:bg-gray-200 transition-colors duration-200"
						>
							<div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
								<link.icon
									className="h-6 w-6 text-sky-600"
									aria-hidden="true"
								/>
							</div>
							<div className="flex-auto">
								<h3 className="text-sm font-semibold leading-6 text-gray-900">
									<div>
										<span className="absolute inset-0" aria-hidden="true" />
										{link.name}
									</div>
								</h3>
								<p className="mt-2 text-sm leading-6 text-gray-600">
									{link.description}
								</p>
							</div>
							<div className="flex-none self-center">
								<ChevronRightIcon
									className="h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</div>
						</li>
					))}
				</ul>
				<div className="mt-10 flex justify-center">
					<div
						className="text-sm font-semibold leading-6 text-sky-600 cursor-pointer hover:border-b-1 border-sky-300 transition-colors duration-200"
						onClick={() => navigate("/")}
					>
						Back to main page
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmationError;
